import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Headline from '../../ui/Headline';
import Image from '../../ui/Image';
import { IImage } from '../../ui/Image/types';
import { IVideo } from '../../ui/Video/types';
import * as styles from './styles.module.scss';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';

type TextItem = {
  text: string;
};

type PageHeaderProps = {
  imageOrVideo: IImage | IVideo;
  shortText?: string;
  title: string;
  textItems: Array<TextItem>;
  pageType?: boolean;
};

const PageHeader: React.FC<PageHeaderProps> = ({ imageOrVideo, title, shortText, textItems, pageType = false }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {pageType ? (
        <div className={styles.containerHomepage}>
          <div>
            {imageOrVideo?.internal?.type === 'ContentfulImage' ? (
              <Image {...imageOrVideo} additionalClass={styles.img} />
            ) : (
              <video src={imageOrVideo?.media?.file?.url} autoPlay muted playsInline loop className={styles.video} />
            )}
          </div>
          <div className={styles.wrapper}>
            {title !== null && (
              <Parallax y={[-20, 20]} disabled={width < 640}>
                <Headline level={1} color="White" additionalClass={styles.hl}>
                  {title}
                </Headline>
              </Parallax>
            )}
            {textItems && (
              <div className="flex justify-center flex-wrap mb-60">
                {textItems.map((item, index) => (
                  <p className={styles.text} key={index}>
                    {item.text}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.containerSubpage}>
          <Row>
            <Col mobile={12} desktop={6} additionalClass={styles.leftCol}>
              <div className="large:pl-70">
                {shortText !== null && <p className={styles.text}>{shortText}</p>}
                {title !== null && (
                  <Headline level={1} color="Black" additionalClass={styles.hl}>
                    {title}
                  </Headline>
                )}
              </div>
            </Col>
            <Col mobile={12} desktop={6}>
              {imageOrVideo?.internal?.type === 'ContentfulImage' ? (
                <Image {...imageOrVideo} additionalClass={styles.img} />
              ) : (
                <video src={imageOrVideo?.media?.file?.url} autoPlay muted playsInline loop className={styles.video} />
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PageHeader;
